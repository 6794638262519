.container.page {
    margin-top:  64px;
    line-height: 23px;

    @include screen-lg {
        max-width:  846px;
        margin-top: 80px;
    }
    @include screen-xl {
        margin-top: 106px;
    }

    h1 {
        text-align: left;
    }
}