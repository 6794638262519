@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@600&display=swap');

.Title_regular-32 {

  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  text-align: left;
}

.Title_tag_regular-32 {

  font-size: 32px;
  font-weight: 400;
  line-height: 44.8px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: SubTitle/Bold 21;
.SubTitle_Bold-21 {

  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

//styleName: SubTitle/Tag Regular 21;
.SubTitle_Tag_Regular-21 {

  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: left;
}


//styleName: Body/Regular 16;
.Body_Regular-16 {

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

//styleName: Body/Tag Regular 16;
.Body_Tag_Regular-16 {

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: Text/Regular 14;
.Text_Regular-14 {

  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

//styleName: Text/Tag Regular 14;
.Text_Tag_Regular-14 {

  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: Caption/Regular 10;
.Captions_Regular-10 {


  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

//styleName: Caption/Tag Regular 10;
.Captions_Tag_Regular-10 {

  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: Title/bold 32;
.Title_bold-32 {

  font-size: 32px;
  font-weight: 500;
  line-height: 35.2px;
  text-align: left;
}

//styleName: Title/tag bold 32;
.Title_tag_bold-32 {

  font-size: 32px;
  font-weight: 500;
  line-height: 44.8px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: SubTitle/Bold 21;
.SubTitle_Bold-21 {


  font-size: 21px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

//styleName: SubTitle/Tag bold 21;
.SubTitle_Tag_bold-21 {

  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: Body/Bold 16;
.Body_Bold-16 {


  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

//styleName: Body/Tag Bold 16;
.Body_Tag_Bold-16 {

  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: Text/Bold 14;
.Text_Bold-14 {


  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
}

//styleName: Text/Tag Bold 14;
.Text_Tag_Bold-14 {

  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.1em;
  text-align: left;
}

//styleName: Caption/Bold 10;
.Caption_Bold-10 {

  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

//styleName: Caption/Tag Bold 10;
.Captions_Tag_Bold-10 {

  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: left;
}


