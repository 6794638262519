.faq-page{
  margin-top: 20px!important;
}
.faq-title {
  font-size: 24px;
}

.faq-path {
  a {
    font-size: 16px;
    color: black;
    margin-right: 10px;
    text-decoration: underline;
  }
}

.faq-content {
  padding-bottom: 40px;
  h1 {
    font-size: 24px;
    color: black;
margin-top: 30px;
    margin-bottom: 0px;
    b {
      font-size: 24§px;
      color: black;
    }
  }

  a {
    text-decoration: underline;
  }
  li{
    margin-bottom: 10px;
  }
  img{
    max-width: 100%;
  }
  details{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  p{
    margin-bottom: 10px;
  }
  .child-content{
    padding-top: 15px;
  }
}
