@use 'colors' as *;

html {
  --s100: #{$s100-day};
  --accent_primary: #{$accent_primary-day};
  --accent_secondary: #{$accent_secondary-day};
  --background: #{$background-day};
  --button_primary: #{$button_primary-day};
  --button_secondary: #{$button_secondary-day};
  --frame_header: #{$frame_header-day};
  --text_contast: #{$text_contast-day};
  --text_primary: #{$text_primary-day};
  --text_secondary: #{$text_secondary-day};
}

