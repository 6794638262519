section.section-1 {
    text-align: center;
    position:   relative;

    margin-top: 64px;
    @include screen-lg {
        margin-top: 120px;
    }
    @include screen-xl {
        margin-top: 106px;
    }

    .bg-1 {
        position:        absolute;
        width:           100%;
        left:            0;
        top:             -200px;
        overflow-x:      hidden;
        display:         none;
        justify-content: center;

        @include screen-sm {
            display: flex;
        }
        @include screen-md {
            top: -144px;
        }
        @include screen-lg {
            top: -200px;
        }

        img {
            width:       1000px;
            margin-left: 40px;

            @include screen-md {
                width:       1000px;
                margin-left: 68px;
            }

            @include screen-lg {
                width:       auto;
                margin-left: 88px;
            }
        }
    }

    .bg-2 {
        position:        absolute;
        display:         none;
        justify-content: center;
        width:           100%;
        top:             -270px;
        left:            -72px;

        @include screen-xm {
            display: flex;
        }
    }

    .container {
        max-width: 420px;
        @include screen-md {
            max-width: 540px;
        }
        @include screen-lg {
            max-width: 846px;
        }

        .bg {
            position:   absolute;
            width:      100%;
            top:        -184px;
            left:       -170px;
            text-align: center;
        }

        h1 {
            position: relative;
        }

        .btn {
            margin-top: 40px;
            @include screen-lg {
                margin-top: 56px;
            }
        }
    }
}

section.section-2 {
    @include screen-xm {
        padding: 0 16px;
    }

    .bg-top {
        position:        absolute;
        width:           100%;
        text-align:      center;
        left:            0;
        top:             -120px;
        display:         flex;
        justify-content: center;

        img {
            margin-left: -16px;

            @include screen-md {
                margin-left: 0;
            }
        }
    }

    .bg-bottom {
        position:        absolute;
        width:           100%;
        left:            0;
        bottom:          -250px;
        display:         flex;
        justify-content: center;

        @include screen-md {
            display: none;
        }
    }

    .container {
        background-color: #F6F9FB;
        border-radius:    32px;
        margin-top:       84px;
        padding:          12px 16px 72px 16px;
        overflow:         hidden;

        @include screen-md {
            margin-top: 150px;
        }
        @include screen-lg {
            border-radius: 40px;
            margin-top:    130px;
            padding:       32px 16px 80px 16px;
        }
        @include screen-xl {
            padding: 24px 72px 132px 72px;
        }
    }

    .scroll-to-explore {
        position:         relative;
        color:            #999FAE;
        background-color: #F6F9FB;
    }

    h1 {
        margin-top: 60px;
        position:   relative;

        @include screen-xl {
            margin-top: 112px;
        }
    }

    .cards {
        display:               grid;
        grid-template-columns: auto;
        grid-gap:              16px;
        margin-top:            56px;
        position:              relative;
        z-index:               10;

        .card {
            display:          flex;
            flex-direction:   column;
            align-items:      start;
            border:           0;
            background-color: $color-white;
            border-radius:    20px;
            padding:          30px 24px;
            box-shadow:       0 6px 10px rgba(#99A1AA, 0.1);
            font-weight:      400;
            font-size:        16px;
            line-height:      22px;
            max-height:       none;

            img {
                width:         40px;
                height:        40px;
                margin-bottom: 12px;

                @include screen-xl {
                    width:         56px;
                    height:        56px;
                    margin-right:  24px;
                    margin-bottom: 0;
                }
            }

            @include screen-xl {
                flex-direction: row;
                max-height:     116px;
                align-items:    center;
            }

            @include screen-xxl {
                font-size:   20px;
                line-height: 28px;
            }
        }

        @include screen-md {
            grid-template-columns: auto auto;
            grid-gap:              28px 24px;
        }
    }
}

section.section-3 {
    overflow: hidden;

    .container {
        margin-top: 64px;
        padding:    15px;

        @include screen-md {
            padding:    0;
            margin-top: 96px;
        }
        @include screen-lg {
            margin-top: 120px;
        }

        .bg-1 {
            position:        absolute;
            display:         none;
            width:           100%;
            justify-content: center;
            transform:       rotate(30deg);

            @include screen-md {
                display:   flex;
                transform: none;
                top:       -250px;
                left:      -66px;
            }
            @include screen-lg {
                top:  -220px;
                left: -90px;
            }
            @include screen-xl {
                top:  -190px;
                left: -85px;
            }
        }

        .bg-2 {
            position:        absolute;
            display:         flex;
            justify-content: center;
            transform:       rotate(14deg);
            width:           100%;

            @include screen-sm {
                justify-content: center;
                top:             -144px;
                left:            -15px;
                height:          100%;
                width:           auto;
            }

            @include screen-md {
                display: none;
            }

            img {
                @include screen-xm {
                    width:       360px;
                    margin-top:  -154px;
                    margin-left: -330px;
                }
            }
        }
    }

    h1 {
        margin-bottom: 52px;
        position:      relative;
    }

    .cards {
        display:               grid;
        grid-template-columns: auto;

        .card {
            display:          flex;
            flex-direction:   column;
            align-items:      start;
            border:           0;
            background-color: $color-white;
            border-radius:    20px;
            padding:          30px 24px;
            box-shadow:       0 6px 10px rgba(#99A1AA, 0.15);
            margin-bottom:    32px;

            img {
                width:         40px;
                height:        40px;
                margin-bottom: 12px;

                @include screen-xl {
                    width:         56px;
                    height:        56px;
                    margin-right:  24px;
                    margin-bottom: 0;
                }
            }

            @include screen-md {
                width: 340px;

                &:nth-child(3), &:nth-child(4) {
                    justify-self: end;
                }
            }

            @include screen-lg {
                width: 410px;
            }

            @include screen-xl {
                flex-direction: row;
                max-height:     112px;
                align-items:    center;
                width:          450px;
                font-size:      20px;
                line-height:    28px;
                margin-bottom:  60px;
            }
        }

        @include screen-md {
            grid-template-columns: 50% 50%;
        }
    }
}

section.section-4 {
    @include screen-xm {
        padding: 0 16px;
    }

    .container {
        background-color: #6FB1F3;
        border-radius:    32px;
        margin-top:       64px;
        color:            $color-white;

        @include screen-md {
            margin-top: 126px;
        }

        @include screen-lg {
            border-radius: 40px;
            margin-top:    170px;
        }

        h1 {
            margin-bottom: 40px;
        }

        .ready {
            padding: 32px 16px;

            @include screen-md {
                padding: 48px 40px;
            }
            @include screen-lg {
                padding: 56px 72px;
            }
        }

        .bg-square {
           // background-image:    url('/skin/img/specular.png');
            background-position: 0;
            background-repeat:   no-repeat;
        }

        .phone-bg {
            mix-blend-mode: soft-light;
            opacity:        0.5;
            position:       absolute;
            width:          300px;
            transform:      rotate(90deg);
            top:            -30%;

            @include screen-xm {
                width: 98%;
            }
            @include screen-sm {
                width:     400px;
                transform: rotate(90deg);
                top:       -30%;
            }
            @include screen-md {
                transform: none;
                width:     360px;
                top:       -16%;
            }
            @include screen-lg {
                width: 480px;
                top:   -20%;
            }
            @include screen-xl {
                width: 580px;
                top:   -34%;
            }
            @include screen-xxl {
                width: 700px;
                top:   -50%;
            }
        }

        .phone-img {
            margin-top:  -40px;
            margin-left: 42px;
            width:       226px;

            @include screen-md {
                margin-left: 60px;
            }

            @include screen-lg {
                margin-left: 120px;
                width:       auto;
            }
        }
    }
}

section.section-5 {
    @include screen-xm {
        padding: 0 16px;
    }

    .container {
        background:    radial-gradient(85.37% 85.37% at 50% 50%, #FFFFFF 0%, #ECF2F6 100%), #FFFFFF;
        border-radius: 32px;
        padding:       32px 16px 40px 16px;
        margin-bottom: 64px;
        margin-top:    32px;

        text-align:    center;

        @include screen-md {
            padding-top:    40px;
            padding-bottom: 54px;
            margin-bottom:  96px;
        }

        @include screen-lg {
            border-radius: 40px;
            margin-top:    56px;
            margin-bottom: 130px;
        }

        .bg-left {
            position:       absolute;
            left:           0;
            top:            0;
            mix-blend-mode: soft-light;
            height:         100%;
        }

        .bg-right {
            position:       absolute;
            right:          0;
            top:            0;
            mix-blend-mode: soft-light;
            height:         100%;
        }

        .wave-1 {
            position: absolute;
            left:     0;
            top:      90px;
            width:    100%;
        }

        .wave-2 {
            position: absolute;
            left:     0;
            top:      140px;
            width:    100%;
        }

        h1 {
            margin-bottom: 12px;
            position:      relative;

            @include screen-lg {
                margin-bottom: 16px;
            }
        }

        p {
            color:         #999FAE;
            margin-bottom: 0;
            font-size:     16px;
            line-height:   22px;
            position:      relative;

            @include screen-lg {
                font-size:   20px;
                line-height: 28px;
            }
        }

        .btn {
            margin-top: 32px;

            @include screen-lg {
                margin-top: 40px;
            }
        }
    }
}
