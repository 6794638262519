html {
    height: 100%;
}

body {
    height:           100%;
    font-family:      $font-dm;
    font-size:        16px;
    line-height:      21px;
    color:            $color-text;
    background-color: #EAEDF1;
}

#content {
    min-height:     100%;
    position:       relative;
    display:        flex;
    flex-direction: column;
}

.container {
    position: relative;
}

@media (min-width: 1440px) {
    .container {
        max-width: 1310px;
    }
}

a {
    text-decoration: none;
    color:           $color-text;

    &:hover {
        color:           $color-text;
        text-decoration: none;
    }
}

h1 {
    font-size:   32px;
    line-height: 36px;
    font-weight: 500;
    text-align:  center;

    b {
        font-weight: 500;
        color:       $color-blue;
    }

    @include screen-lg {
        font-size:   56px;
        line-height: 62px;
    }
}
body.short {
    h1 {
        @include screen-xm {
            font-size:   20px;
            line-height: 46px;
        }
    }
}

ul.nav {
    @include reset-list;
    display:   flex;
    flex-wrap: nowrap;

    li {
        background-color: #ECF2F6;
        padding:          11px 12px 12px 0;
        font-size: 14px;
        white-space:      nowrap;

        &:first-child {
            padding-left:              20px;
            border-top-left-radius:    50px;
            border-bottom-left-radius: 50px;
        }

        &:last-child {
            padding-right:              20px;
            border-top-right-radius:    50px;
            border-bottom-right-radius: 50px;
        }

        a {
            line-height: 21px;
        }
    }

    &.grey {
        li {
            background-color: rgba(236, 242, 246, 0.1);
        }
    }
}

div.logo, a.logo {
    display:     flex;
    font-family: $font-pp;
    font-size:   22px;
    line-height: 22px;
    font-weight: 600;
    align-items: center;

    img {
        width:         40px;
        height:        40px;
        border-radius: 12px;
        margin-right:  12px;
    }

    b {
        color: $color-blue;
    }
}

body.short {
    div.logo, a.logo {
        @include screen-xm {
            font-size: 16px;
            img {
                width: 30px;
                height: 30px;
                border-radius: 8px;
            }
        }
    }
}

.mobile {
    display:       inline-block;
    background:    linear-gradient(360deg, #0096F9 0.4%, #36BDED 100%);
    box-shadow:    0 0.8px 2px -3.2px rgba(0, 0, 0, 0.28);
    border-radius: 12px;
    transform:     rotate(-1deg);
    color:         $color-white;
    padding:       0 12px;

    &.mobile-sm {
        border-radius: 6px;
        padding:       0 6px;
        margin:        0 6px;
    }

    &:hover {
        color: rgba($color-white, 0.8);
    }
}

.btn {
    border:        0;
    border-radius: 100px;
    outline:       none !important;
    box-shadow:    0 14px 20px rgba(35, 34, 87, 0.12), 0 48px 48px rgba(0, 0, 0, 0.04) !important;
    animation:     none !important;
    transition:    none !important;
    padding:       23px 32px 23px 32px;
    font-size:     18px;
    line-height:   24px;
    font-weight:   500;
    position:      relative;
    white-space:   nowrap;

    @include screen-lg {
        font-size:   20px;
        line-height: 26px;
    }

    img {
        position: absolute;
        width:    48px;
        height:   48px;
        right:    12px;
        top:      12px;
    }

    &.btn-secondary {
        background-color: $color-dark;
        padding-right:    80px;
    }

    &.btn-white {
        background-color: $color-white;
        color:            #1293DA;
    }
}
