// Screen sizes

// Colors
$color-text:  #27272E;
$color-dark:  #27272E;
$color-white: #FFFFFF;
$color-blue:  #51A1DE;

// Typography
$font-dm:     'DM Sans', sans-serif;
$font-pp:     'Poppins', sans-serif;
