header {
    padding-top: 32px;
    position:    relative;
    z-index:     10;

    a.logo {
        b {
            color: $color-text;
        }
    }
}

body.short header {
    .header-logo {
        justify-content: flex-start !important;
    }
    .header-nav {
        display: none !important;
    }
}