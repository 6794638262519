footer {
    background-color:        $color-dark;
    color:                   $color-white;
    padding:                 40px 32px;
    border-top-left-radius:  40px;
    border-top-right-radius: 40px;
    justify-self:            flex-end;
    margin-top:              auto;

    @include screen-xm {
        padding-left:  16px;
        padding-right: 16px;
    }

    @include screen-md {
        border-top-left-radius:  60px;
        border-top-right-radius: 60px;
    }

    @include screen-md {
        border-top-left-radius:  80px;
        border-top-right-radius: 80px;
    }

    @include screen-xl {
        border-top-left-radius:  110px;
        border-top-right-radius: 110px;
    }

    .container {
        padding-left:  0;
        padding-right: 0;
    }

    ul.nav {
        li {
            img {
                width:  28px;
                height: 28px;
                margin: -7px 12px -4px -12px;
            }
            @include screen-xm {
                border-radius:50px;
                padding: 20px;
                margin:  20px 0;
                text-align: center;
            }
        }
        @include screen-xm {
            display: block;
        }
    }

    hr {
        @include reset-indents;
        margin-top:    28px;
        margin-bottom: 28px;
        border:        0;
        border-top:    solid 1px $color-white;
        opacity:       0.2;

        @include screen-md {
            margin-top:    32px;
            margin-bottom: 28px;
        }

        @include screen-lg {
            margin-top:    40px;
            margin-bottom: 32px;
        }
    }

    a {
        color: $color-white;

        &:hover {
            color: $color-white;
        }
    }
}

body.short footer {
    .footer-logo {
        justify-content: flex-start !important;
        margin-bottom: 0 !important;
    }
    hr, .copyright-row, .footer-nav {
        display: none !important;
    }

    @include screen-xm {
        border-radius: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
