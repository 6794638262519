@mixin reset-indents {
    margin:  0;
    padding: 0;
}

@mixin reset-list {
    @include reset-indents;
    list-style: none;
}

@mixin screen-xm {
    @media (max-width: 576px) { @content; }
}
@mixin screen-sm {
    @media (min-width: 576px) { @content; }
}
@mixin screen-md {
    @media (min-width: 768px) { @content; }
}
@mixin screen-lg {
    @media (min-width: 992px) { @content; }
}
@mixin screen-xl {
    @media (min-width: 1200px) { @content; }
}
@mixin screen-xxl {
    @media (min-width: 1440px) { @content; }
}