.react-tabs {
  .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0;
    list-style: none;
    background-color: #FFFFFF;
    border-radius: 26px;
  }


  .react-tabs__tab {
    padding: 10px 20px;
    flex-grow: 1;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 26px;
    border: none;
    text-align: center;
    color: #8C9FB1;

    &--selected {
      background-color: #0096F9;
      color: #E5F1F9;
    }
  }

  .react-tabs__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.list-row {
  background-color: #FFFFFF;
  border-radius: 14px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  background-image: url(../assets/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
  cursor: pointer;
  padding: 12px 40px 12px 15px;

  .label {
    color: #223344;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
  }

  .value {
    text-align: left;
    margin-left: auto;
    color: #8C9FB1;
  }

}

.esim-content {
  padding: 20px;
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  position: relative;
  z-index: 1;
}

.flag {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-size: auto 100%;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
}

.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  .input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    position: relative;

    input {
      width: 100%;
      padding: 12px 16px 12px 30px;
      border: none;
      border-radius: 8px;
      background-color: #FFFFFF;
      color: #8C9FB1;
    }

    .search-icon {
      position: absolute;
      margin-left: 5px;
    }

    .reset-icon {
      position: absolute;
      cursor: pointer;
      right: 5px;
    }
  }


}

.not-found {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
  color: #8C9FB1;

  img {
    margin-bottom: 20px;
  }
}

.bundle-header {
  font-size: 21px;
  display: flex;
  margin-top: 0;
  align-items: center;
}


.tariffs-list {
  list-style: none;
  padding: 0;

  li {
    background-color: var(--s100);
    border: solid 2px var(--s100);
    border-radius: 14px;
    padding: 8px 15px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .sizes {
      width: 30%;
    }

    .label {
      @extend .Body_Bold-16;
      color: var(--text_primary);
    }

    .value {
      @extend .Body_Regular-16;
      color: var(--text_secondary);
    }

    .discount {
      @extend .Body_Bold-16;
      background-image: url("../assets/discount.svg");
      background-repeat: no-repeat;
      width: 63px;
      height: 40px;
      color: var(--text_contast);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

    }

    .checkwrap {
      display: flex;
      margin-left: auto;
    }

    .check {
      background-image: url("../assets/radio.svg");
      background-repeat: no-repeat;
      width: 20px;
      height: 40px;
      margin-left: 10px;
      background-position: center;
    }

    &.selected {
      background-color: var(--accent_secondary);
      border-color: var(--accent_primary);

      .check {
        background-image: url("../assets/radio_checked.svg");

      }
    }
  }

}

.big-button {
  @extend .SubTitle_Bold-21;
  background-color: var(--button_primary);
  border-radius: 8px;
  padding: 16px 8px;
  color: var(--text_contast);
  line-height: 29px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.payframe {
  border: 0;
  margin: 20px;
  height: 100vh;
}
