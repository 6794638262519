@mixin marginStyles($name) {
    @for $i from 0 through 100 {
        .mt-#{$name}-#{$i}px {
            margin-top: #{$i}px !important;
        }
        .mb-#{$name}-#{$i}px {
            margin-bottom: #{$i}px !important;
        }
        .ml-#{$name}-#{$i}px {
            margin-left: #{$i}px !important;;
        }
        .mr-#{$name}-#{$i}px {
            margin-right: #{$i}px !important;;
        }
    }
}

.mb-0-last-child:last-child {
    margin-bottom: 0;
}

@media (max-width: 575.98px) {
    @include marginStyles(xs);
}
@media (min-width: 576px) {
    @include marginStyles(sm);
}
@media (min-width: 768px) {
    @include marginStyles(md);
}
@media (min-width: 992px) {
    @include marginStyles(lg);
}
@media (min-width: 1200px) {
    @include marginStyles(xl);
}
@media (min-width: 1400px) {
    @include marginStyles(xxl);
}
@media (min-width: 1600px) {
    @include marginStyles(xml);
}
@media (min-width: 1900px) {
    @include marginStyles(xsl);
}

@for $i from 0 through 100 {
    .mt-#{$i}px {
        margin-top: #{$i}px !important;
    }
    .mb-#{$i}px {
        margin-bottom: #{$i}px !important;
    }
    .ml-#{$i}px {
        margin-left: #{$i}px !important;;
    }
    .mr-#{$i}px {
        margin-right: #{$i}px !important;;
    }
}